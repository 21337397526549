
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as hotelsXE5J31W3vVMeta } from "/vercel/path0/pages/hotels.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93zKgFSelgFtMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexp3qWQtgtPJMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "faq___nl",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "hotels___nl",
    path: "/hotels",
    component: () => import("/vercel/path0/pages/hotels.vue")
  },
  {
    name: "hotels___en",
    path: "/en/hotels",
    component: () => import("/vercel/path0/pages/hotels.vue")
  },
  {
    name: "hotels___de",
    path: "/de/hotels",
    component: () => import("/vercel/path0/pages/hotels.vue")
  },
  {
    name: "hotels___fr",
    path: "/fr/hotels",
    component: () => import("/vercel/path0/pages/hotels.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "privacy___nl",
    path: "/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/datenschutz-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/confidentialite-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "vacancies-item___nl",
    path: "/vacatures/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies-item___en",
    path: "/en/vacancies/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies-item___de",
    path: "/de/stellenangebote/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies-item___fr",
    path: "/fr/emplois/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies___nl",
    path: "/vacatures",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___en",
    path: "/en/vacancies",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___de",
    path: "/de/stellenangebote",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___fr",
    path: "/fr/emplois",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  }
]