import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faCalendarDays,
	faEnvelope,
	faTv,
	faCocktail,
	faShower,
	faWifi,
	faAirConditioner,
	faBedFront,
	faBellConcierge,
	faPhone,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronUp,
	faHotTubPerson,
	faCoffee,
	faUtensils,
	faWineGlass,
	faStore,
	faBicycle,
	faCroissant,
	faCouch,
	faTree,
	faWater,
	faBath,
	faLink,
	faVault,
	faBuildingColumns,
	faSpa,
	faGolfFlagHole,
	faPersonHiking,
	faPersonSkiing,
	faMusic,
	faMasksTheater,
	faMicrophone,
	faGlobe,
} from '@fortawesome/pro-light-svg-icons';
import {
	faCircleCheck,
	faChevronDown,
	faBars,
	faPlus,
	faMinus,
	faLocationDot,
	faTimes,
	faArrowRightLong,
	faArrowLeftLong,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlay, faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faTiktok,
	faWhatsapp,
	faYoutube,
	faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faStar, faQuoteLeft, faStarHalf } from '@fortawesome/free-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faBedFront,
	faBellConcierge,
	faTv,
	faUtensils,
	faWifi,
	faStore,
	faWineGlass,
	faCocktail,
	faMicrophone,
	faHotTubPerson,
	faCoffee,
	faGlobe,
	faAirConditioner,
	faShower,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronUp,
	faChevronDown,
	faPlus,
	faMinus,
	faLocationDot,
	faCalendarDays,
	faArrowLeftLong,
	faArrowRightLong,
	faQuoteLeft,
	faBars,
	faEnvelope,
	faPhone,
	faTimes,
	faCircleCheck,
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faPlay,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faYoutube,
	faSearch,
	faStar,
	faStarHalf,
	faBicycle,
	faCroissant,
	faCouch,
	faTree,
	faWater,
	faBath,
	faLink,
	faVault,
	faBuildingColumns,
	faSpa,
	faGolfFlagHole,
	faPersonHiking,
	faPersonSkiing,
	faMusic,
	faMasksTheater,
	faXTwitter,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
